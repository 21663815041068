<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Agents</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Agents"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-btn depressed light :to="{ name: 'module-drum-admin-agents' }">
            Return to active list
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="agents"
        disable-pagination
        hide-default-footer
        no-data-text="There are currently no agents archived"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Agent</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.agent.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Admin",
          disabled: true,
        },
        {
          text: "Agents",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-admin-agents",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Website", value: "website" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        agent: {},
      },
    };
  },

  computed: {
    agents() {
      let agents = this.$store.state.drum.admin["archivedAgents"];

      if (this.searchTerm !== "") {
        agents = agents.filter(c => {
          const name = c.name.toLowerCase();
          const email = c.email.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            email.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return agents;
    },
  },

  methods: {
    openRestore(agent) {
      this.restoreDialog.agent = agent;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.agent = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("drum/admin/restoreAgent", {
          appId,
          agentId: this.restoreDialog.agent.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
